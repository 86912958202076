import React, { useEffect } from 'react';
//theme
//libs
import config from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faUsers } from '@fortawesome/free-solid-svg-icons';
//views
//const
const logo = require("../../../assets/img/logo.png");
const playStoreBadge = require("../../../assets/img/google-play-badge.png");
const appStoreBadge = require("../../../assets/img/appstore-badge.png");
//View
const HomeView = () => {
    //effects
    useEffect(() => {
        documentApp();
        return () => {

        }
    }, [])

    const documentApp = () => {
        document.title = `${config.appName} | Powered By ${config.provider}`;
        let link = document.querySelector('link[rel="shortcut icon"]') ||
            document.querySelector('link[rel="icon"]');
        if (!link) {
            link = document.createElement('link');
            link.id = 'favicon';
            // @ts-ignore
            link.rel = 'shortcut icon';
            document.head.appendChild(link);
        }
        // @ts-ignore
        link.href = require("../../../assets/img/favicon.ico");
        document.head.appendChild(link);
    }

    return (
        <div
            style={{
                backgroundColor: '#eee',
                color: '#333',
                width: '100vw',
                minHeight: '100vh',
                overflowX: 'hidden',
                overflowY: 'auto',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            <img
                src={logo}
                style={{
                    width: '16rem',
                    boxShadow: '0px 2px 16px rgba(0,0,0,0.2)',
                    borderRadius: '20%',
                    margin: '20px 20px',
                    marginTop: '5rem',
                    position: 'relative',
                    zIndex: 10,
                }}
                alt={"logo"}
            />
            {/*Sección de descarga*/}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                {/*Pasajeros*/}
                <div
                    style={{
                        margin: '20px 20px',
                        padding: 20,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        textAlign: 'center',
                        flexWrap: 'wrap',
                        maxWidth: 300
                    }}
                >
                    <FontAwesomeIcon
                        icon={faUsers}
                        style={{
                            fontSize: '5rem', color: config.colorPrimary, marginBottom: 10
                        }}
                    />
                    <legend
                        style={{
                            fontWeight: 'bold', fontSize: 20, textAlign: "center", width: "100%"
                        }}
                    >
                        Pasajeros
                    </legend>
                    <p>
                        Descarga la app para pasajeros y disfruta de tus viajes de forma segura
                    </p>
                    <div
                        style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center"
                        }}
                    >
                        {config.playStorePassengerId.length ? <img
                            src={playStoreBadge}
                            style={{
                                width: '10rem', cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open(`https://play.google.com/store/apps/details?id=${config.playStorePassengerId}`)
                            }}
                            alt={"google-play-badge"}
                        /> : null}
                        {config.appStorePassengerId.length ? <img
                            src={appStoreBadge}
                            style={{
                                width: '8rem', cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open(`itms-apps://itunes.apple.com/app/apple-store/${config.appStorePassengerId}?mt=8`)
                            }}
                            alt={"apple-appstore-badge"}
                        /> : null}
                    </div>
                </div>

                {/*Conductores*/}
                <div
                    style={{
                        margin: '20px 20px',
                        padding: 20,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        textAlign: 'center',
                        flexWrap: 'wrap',
                        maxWidth: 300
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCar}
                        style={{
                            fontSize: '5rem', color: config.colorPrimary, marginBottom: 10
                        }}
                    />
                    <legend
                        style={{
                            fontWeight: 'bold', fontSize: 20, textAlign: "center", width: "100%"
                        }}
                    >
                        Conductores
                    </legend>
                    <p>
                        Descarga la app para conductores y genera más ganancias
                    </p>
                    <div
                        style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center"
                        }}
                    >
                        {config.playStoreDriverId.length ? <img
                            src={playStoreBadge}
                            style={{
                                width: '10rem', cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open(`https://play.google.com/store/apps/details?id=${config.playStoreDriverId}`)
                            }}
                            alt={"google-play-badge"}
                        /> : null}
                        {config.appStoreDriverId.length ? <img
                            src={appStoreBadge}
                            style={{
                                width: '8rem', cursor: 'pointer'
                            }}
                            onClick={() => {
                                window.open(`itms-apps://itunes.apple.com/app/apple-store/${config.appStoreDriverId}?mt=8`)
                            }}
                            alt={"apple-appstore-badge"}
                        /> : null}
                    </div>
                </div>
                {/*Distribuidores*/}
                <div
                    style={{
                        margin: '20px 20px',
                        padding: 20,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        textAlign: 'center',
                        flexWrap: 'wrap',
                        maxWidth: 300,
                    }}
                >
                    <i
                        className="fas fa-dumpster"
                        style={{
                            fontSize: '5rem', color: config.colorPrimary, marginBottom: 10
                        }}
                    />
                    <legend
                        style={{
                            fontWeight: 'bold', fontSize: 20, textAlign: "center", width: "100%"
                        }}
                    >
                        Distribuidores
                    </legend>
                    <p>
                        ¿Tienes un negocio propio? ¿Quiéres ganar dinero extra con nosotros vendiendo recargas a
                        nuestros usuarios?
                        <br /><br />Comunícate con nosotros
                    </p>
                    <a
                        href={`mailto:${config.email}`}
                        style={{
                            padding: 40
                        }}
                    >
                        {config.email}
                    </a>
                </div>

            </div>

            {/*Footer*/}
            <div
                style={{
                    backgroundColor: config.colorDark,
                    padding: 10,
                    width: '100%',
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    flexWrap: "wrap"
                }}
            >
                <a
                    href={`${config.host}/uploads/privacity.html`}
                    style={{
                        padding: '10px 10px', color: '#fff',
                    }}
                >
                    Política de Privacidad
                </a>
                <a
                    href={`${config.host}/uploads/terms.html`}
                    style={{
                        padding: '0px 10px', color: '#fff',
                    }}
                >
                    Términos y Cóndiciones
                </a>
                <h6
                    style={{
                        width: "100%"
                    }}
                >
                    Versión {config.version}
                    <br />© {config.appName}. Todos los derechos reservados
                    {config.provider.length ? <React.Fragment>
                        <br /> Powered By {config.provider}
                    </React.Fragment> : ""}
                </h6>
            </div>
        </div>
    );
}
export default HomeView;
