const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://rushcab.app' : 'http://192.168.1.106:3019',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://rushcab.app' : 'http://192.168.1.106:3019',
    api: 'api/',
    apiSocket: 'rushcab::1.2',
    nameDir: 'rushcab',
    package: 'app.rushcab.web',
    version: '1.0.1',
    googleAPIKey: 'AIzaSyBZpXkYzgZtxuP8G7K2iAxTUZ5qweXA39Y',
    appName: 'RushCab',
    provider: '',
    colorPrimary: '#d39024',
    colorDark: '#165785',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.rushcab.passenger',
    playStoreDriverId: 'app.rushcab.driver',
    appStorePassengerId: '6446802743',
    appStoreDriverId: '6446802574',
    email: "Darwin.oclocho@gmail.com",
};
export default config;
